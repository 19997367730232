import React, { Fragment } from "react";
//import { images_products_route } from "../api/productApi";
import { isIOS } from "react-device-detect";
import ImageLoader from "./ImageLoaderComponent";


function Picture(props) {

  //let imgRoute = images_products_route;
  const dummy = props.dummy;
  //console.log("dummy in picture", dummy);

  /* if(props.route){
    imgRoute = props.route;
  }

  if(props.src){
    if(props.src.startsWith('/') ){
      imgRoute = '';
    }
  }else{
    imgRoute = '';
  } */

  let iOSVersion = null;

  if (isIOS) {
    const iOSVersionMatch = window.navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    if (iOSVersionMatch) {
      iOSVersion = parseInt(iOSVersionMatch[1], 10);
    }
  }

  const validationRegex = /undefined$/
  const { src } = props;
  if (src && typeof src === "string") {
    const errs = src.match(validationRegex);
    if (errs) return;
  }

  //imgRoute += props.src;

  return (
    <Fragment>
      {/* {isIOS && (
        <p>iOS version: {iOSVersion}</p>
      )}
      {!isIOS && (
        <p>Not an iOS device</p>
      )} */}

      {iOSVersion > 13 || !isIOS ? (
        <ImageLoader src={props.src} styleGroup={props.styleGroup} stylePicture={props.stylePicture} alt={props.alt} dummy={dummy} />
      ) : (
        <picture>
          <source srcSet={props.src} media="(min-width: 360px)" />
          <img className={`${props.styleGroup} w-100 h_auto_img`} src={props.src} alt={props.alt} loading="lazy" height="100" width="100" />
        </picture>
      )}


    </Fragment>

  );
}

export default Picture;
