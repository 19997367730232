import Style from "../scss/text.module.scss";

export function TitleH1Component(props) {
    const {html}=props;
    return (
        <h1 className={`${Style.h1} ${props.styleGroup}`}>
            {html ? (
            <span dangerouslySetInnerHTML={{ __html: html }} ></span>
            ) : 
            (props.children)}
            </h1>
    );
}