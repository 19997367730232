import Style from "../scss/text.module.scss";

export function ParagraphComponent(props) {
    const { html, styleGroup, children } = props;
    return (
                 <p className={`${Style.p} ${props.styleGroup} ${Style.textosApis}`} >  
                  {html ? (
                    <span dangerouslySetInnerHTML={{ __html: html }} />
                ) : (
                    children
                )}</p>
            
        
    );
}