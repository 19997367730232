import Image from "next/image";

export default function Picture({
  height,
  width,
  lazy = true,
  src,
  styleGroup,
  stylePicture,
  alt = 'Prodesa image',
}) {
  return (
    <Image
      className={`w-100 ${stylePicture ? stylePicture : ""} h_auto_img ${
        styleGroup ? styleGroup : ""
      }`}
      src={src}
      alt={alt ?? "Prodesa image"}
      height={height}
      width={width}
      title={alt}
      loading={lazy ? "lazy" : "eager"}
      lazy={`${lazy}`}
      priority={!lazy}
    />
  );
}
