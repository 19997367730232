import { getVariables } from "@/config";

const { SERVER_API_URL, API_URL, TOKEN } = getVariables();

const HEADERS = {
    'Authorization': `Bearer ${TOKEN}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': 'Content-Type',
}

export default async function fetchDataServerSide(path: string, ssr: boolean = false, cacheTime: number = 60, init?: RequestInit) {
    const apiUrl = ssr ? SERVER_API_URL : API_URL;
    const response = await fetch(`${apiUrl}/${path}`, {
        headers: HEADERS,
        next: { revalidate: cacheTime },
        ...init
    })
    if(!response.ok){
        throw new Error('Not found');
    }
    return response.json();
}


