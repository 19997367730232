import React, { useEffect, Fragment } from "react";
import load from "../scss/loader.module.scss";

const Loader = ( props) => {
  useEffect(() => {
    //console.log("loaders ", load);
  }, []);

  return (
    <Fragment>
      <div className={`d-flex justify-content-center ${props.styleComponent}`}>
        <div className={`d-flex align-items-center `}>
          <div className={load.loader}></div>
        </div>
      </div>
    </Fragment>
  );
};

export default Loader;