import fetchDataServerSide from "@integrations/prodesa/connection";
import { ImageType, TextoType, OficinasType, SeccionType, SeoType } from "@integrations/prodesa/definitions";

export async function getImagesByGalleryKey(galleryKey: string, ssr: boolean = false, cacheTime: number = 60) {
    const queryParams = `galeria.llave=${galleryKey}&visible=true&order[orden]=asc&groups[]=read`;
    let gallery: ImageType[] = [];
    try {
        const data = await fetchDataServerSide(`core_images?${queryParams}`, ssr, cacheTime);
        gallery = data['hydra:member'];
    } catch (error) {
        console.error("Error on function getAllTexts, message:", error.message);
    }
    return gallery;
}

export async function getImageByKey(key: string, ssr: boolean = false, cacheTime: number = 60) {
    const queryParams = `llave=${key}&groups[]=read`;
    let image: ImageType | null = null;
    try {
        const data = await fetchDataServerSide(`core_images?${queryParams}`, ssr, cacheTime);
        image = data['hydra:member'][0];
    } catch (error) {
        console.error("Error on function getImageByKey, message:", error.message);
    }
    return image;
}

export async function getAllTexts(ssr: boolean = false, cacheTime: number = 60) {
    let texts: TextoType[] = [];
    try {
        const data = await fetchDataServerSide(`textos`, ssr, cacheTime);
        texts = data['hydra:member'];
    } catch (error) {
        console.error("Error on function getAllTexts, message:", error.message);
    }
    return texts;
};

export async function getOffices(ssr: boolean = false, cacheTime: number = 60) {
    let offices: OficinasType[] = [];
    try {
        const data = await fetchDataServerSide('oficinas', ssr, cacheTime);
        offices = data['hydra:member'];
    } catch (error) {
        console.error("Error on function getOffices, message:", error.message);
    }
    return offices;
}

export async function getSections(visible: boolean = true, ssr: boolean = false, cacheTime: number = 60, queryParams: string[] = []) {
    let sections: SeccionType[] = [];
    try {
        if (visible) queryParams.push('visible=true');
        const data = await fetchDataServerSide(`seccions?${queryParams.join('&')}`, ssr, cacheTime);
        sections = data['hydra:member'];
    } catch (error) {
        console.error("Error on function getSections, message:", error.message);
    }
    return sections;
}

export async function getBlogByID(id: string, ssr: boolean = false, cacheTime: number = 60) {
    try {
        return await fetchDataServerSide(`blogs/${id}`, ssr, cacheTime);
    } catch (error) {
        console.error("Error on function getBlogByID message:", error);
    }
    return null;
}

export async function getBlogBySlug(slug: string, ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`blogs?pagination=true&itemsPerPage=1&slug=${slug}`, ssr, cacheTime);
        return data["hydra:member"][0];
    } catch (error) {
        console.error("Error on function getBlogBySlug message:", error);
    }
    return null;
}

export async function getAllBlogs(ssr: boolean = false, cacheTime: number = 60) {
    let blogs: any[] = [];
    try {
        const data = await fetchDataServerSide(`blogs?visible=1&itemsPerPage=250`, ssr, cacheTime);
        blogs = data['hydra:member'];
    } catch (error) {
        console.error(error);
        console.error("Error on function getAllBlogs message:", error);
    }
    return blogs;
}

export async function getBlogsByQuery(query: string, ssr: boolean = false, cacheTime: number = 60) {
    let blogs: any[] = [];
    try {
        const data = await fetchDataServerSide(`blogs?${query}`, ssr, cacheTime);
        blogs = data['hydra:member'];
    } catch (error) {
        console.error(error);
        console.error("Error on function getAllBlogs message:", error);
    }
    return blogs;
}

export async function getPageSeoByUrl(url: string, ssr: boolean = false, cacheTime: number = 60): Promise<SeoType | null> {
    try {
        const data = await fetchDataServerSide(`seos?url=${url}`, ssr, cacheTime);
        return data['hydra:member'][0];
    } catch (error) {
        console.error(`Error on function getPageSeoByUrl message: (url:${url})`, error);
    }
    return null;
}

export async function getAllCategories(ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`categorias_blogs?visible=1&order[orden]=asc`, ssr, cacheTime);
        return data['hydra:member'];
    } catch (error) {
        console.error("Error on function getAllCategories message:", error);
    }
    return null;
}

export async function getProject(slug: string, ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`proyectos/${slug}`, ssr, cacheTime);
        if (data) {
            const { esmacro } = data;
            if (!esmacro) {
                return data;
            }
        }
    } catch (error) {
        console.error("Error on function getProject message:", error);
    }
    return null;
}

export async function getMacroProject(slug: string, ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`proyectos/${slug}`, ssr, cacheTime);
        if (data) {
            const { esmacro } = data;
            if (esmacro) {
                return data;
            }
        }
    } catch (error) {
        console.error("Error on function getMacroProject message:", error);
    }
    return null;
}

export async function getAllProjects(ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`proyectos?visible=1&esmacro=false&groups[]=minimo&itemsPerPage=250`, ssr, cacheTime);
        return data['hydra:member'];
    } catch (error) {
        console.error("Error on function getAllProjectss message:", error);
    }
    return null;
}

export async function getProjectsByQuery(query: string = '', ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`proyectos?${query}`, ssr, cacheTime);
        return data['hydra:member'];
    } catch (error) {
        console.error("Error on function getAllProjectss message:", error);
    }
    return null;
}

export async function getProjectsByQueryWithPagination(query: string = '', ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`proyectos?${query}`, ssr, cacheTime);
        return data;
    } catch (error) {
        console.error("Error on function getAllProjectss message:", error);
    }
    return null;
}

export async function getAllMacroprojects(ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`proyectos?visible=1&esmacro=true&groups[]=minimo&itemsPerPage=250`, ssr, cacheTime);
        return data['hydra:member'];
    } catch (error) {
        console.error("Error on function getAllMacroprojects message:", error);
    }
    return null;
}

export async function getCities(ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`ciudads?visible=1&order[orden]=asc`, ssr, cacheTime);
        return data['hydra:member'];
    } catch (error) {
        console.error("Error on function getCities message:", error);
    }
    return [];
};

export async function getFaqsWithPagination(query:string, ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`preguntas?${query}`, ssr, cacheTime);
        return data;
    } catch (error) {
        console.error("Error on function getFaqs message:", error);
    }
    return [];
};

export async function getFaqs(query:string, ssr: boolean = false, cacheTime: number = 60) {
    try {
        const data = await fetchDataServerSide(`preguntas?${query}`, ssr, cacheTime);
        return data['hydra:member'];
    } catch (error) {
        console.error("Error on function getFaqs message:", error);
    }
    return [];
};

export async function getFinancialEntities (query:string, ssr: boolean = false, cacheTime: number = 60) {
  try {
    const data = await fetchDataServerSide(`financiera_proyectos?${query}`, ssr, cacheTime);
    return data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};

export async function getContacts (query:string, ssr: boolean = false, cacheTime: number = 60) {
  try {
    const data = await fetchDataServerSide(`contacto_seccions?${query}&order[tipo.id]=asc`, ssr, cacheTime);
    return data['hydra:member'];
  } catch (error) {
    console.error(error);
    return [];
  }
};